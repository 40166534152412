import { createStore } from 'redux';

const initialState = {
  sidebarShow: 'responsive',
  isAuthenticated: false,
  user: null
};

// Action types
const SET_ISAUTHENTICATED = 'SET_ISAUTHENTICATED';
const SET_SIDEBARSHOW = 'SET_SIDEBARSHOW';
const SET_USER = 'SET_USER';

// Action creators
export const setIsAuthenticated = (isAuthenticated) => ({
  type: SET_ISAUTHENTICATED,
  payload: isAuthenticated
});
export const setSidebarShow = (sidebarShow) => ({
  type: SET_SIDEBARSHOW,
  payload: sidebarShow
});
export const setUser = (user) => ({
  type: SET_USER,
  payload: user
});

// Reducer
const changeState = (state = initialState, action) => {
  switch (action.type) {
    case SET_ISAUTHENTICATED:
      return { ...state, isAuthenticated: action.payload };
    case SET_SIDEBARSHOW:
      return { ...state, sidebarShow: action.payload };
    case SET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

// Store creation
const store = createStore(changeState);

export default store;