import React, { Component, useEffect, useState } from 'react';
import { HashRouter, Route, Switch, useHistory } from 'react-router-dom';
import './scss/style.scss';
// import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthenticated } from './store';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.isAuthenticated);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated') === 'true';
    dispatch(setIsAuthenticated(authStatus));
  }, [isAuthenticated]);

    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
              {/* <Route path="/" render={() => (
                isAuthenticated ? (
                  <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                ) : (
                  <Redirect to="/login" />
                )
              )} /> */}
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
}

export default App;
